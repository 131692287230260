import React, { Component } from "react";

export default class OurCommitment extends Component {
  render() {
    return (
      <div className="our-commitment">
        <h2>Our commitment to the word</h2>
        <p>
          At Gracehouse, we believe in the importance and power of God's word.
        </p>
        <p>
          We believe that God uses His Word to transform us into the image and
          likeness of Jesus our Saviour.
        </p>
        <p>
          Here on our website, you can listen to weekly sermons. If you'd prefer
          to listen on the go we have YouTube, Spotify Podcast and Apple Podcast
          links that you can find below.
        </p>
        <p>
          You can also follow our services live here on our website every Sunday
          at 9:30am. Simple visit the website and click on the link to watch
          the live service.
        </p>
        <div className="orange-border-container"></div>
      </div>
    );
  }
}
